<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';     // <--- ADDED
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import List from '@ckeditor/ckeditor5-list/src/list';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';

/**
 * Form Validation component
 */
export default {
  name: 'about',
  page: {
    title: "About",
    meta: [{ label: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "About",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "About",
          active: true
        }
      ],
      form: {
        id: null,
        description: null,
        image: null
      },
      preview: null,
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Autoformat,
          BlockQuote,
          Heading,
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          ParagraphPlugin,
          Base64UploadAdapter,
          Alignment,
          List,
          Indent,
          IndentBlock
        ],
        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'bulletedList', 'numberedList', 'outdent', 'indent', 'imageUpload', 'blockQuote' ],
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        image: {
          toolbar: [
            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative'
          ]
        },
      },
      isError: false,
      errorMessage: null,
      isImageEmtpy: false,
      isDescriptionEmtpy: false
    };
  },
  mounted() {
    this.fetchAbout()
  },
  methods: {
    fetchAbout() {
      this.$axios.get('/about')
        .then( response => {
          const result = response.data.data;
          if(result) {
            this.form.id = result.id
            this.form.description = result.description
            this.preview = result.image_url
          }
        })
    },
    onSubmit() {
      // validate
      if (this.form.id) {
        // update mode
        this.isDescriptionEmtpy = (this.form.description === null || this.form.description == '')? true : false

        if(this.isDescriptionEmtpy) {
          return false
        }

        let param = new FormData();
        param.append('_method', 'patch')
        param.append('id', this.form.id)
        param.append('description', this.form.description) 
        if (this.form.image) {
          param.append('image', this.form.image)
        }
        
        this.$axios.post('/about', param)
        .then( (response) => { 
          console.log(response.data)
          this.isError = false
          Swal.fire("Success", "About Updated Successfully", "success");
          this.fetchAbout()
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })

      } else {
        // create mode
        this.isImageEmtpy = (this.form.image === null || this.form.image == '')? true : false
        this.isDescriptionEmtpy = (this.form.description === null || this.form.description == '')? true : false

        if(this.isDescriptionEmtpy || this.isImageEmtpy) {
          return false
        }

        let param = new FormData();
        param.append('description', this.form.description) 
        param.append('image', this.form.image)
        
        this.$axios.post('/about', param)
        .then( (response) => { 
          console.log(response.data)
          this.isError = false
          Swal.fire("Success", "About Updated Successfully", "success");
          this.fetchAbout()
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
    upload(event) {
      if(event.target.files[0] !== undefined){
        console.log(event.target.files[0].size)
        let extension = event.target.files[0].name.split(".").pop()
        let allowedExtention = ['jpg','jpeg','png']
        if(allowedExtention.includes(extension.toLowerCase()) ){
          // const fileSize = Math.round((event.target.files[0].size / 1024)); 
          // if (fileSize <= 2048) {
            this.form.image = event.target.files[0]
            this.preview = URL.createObjectURL(this.form.image);
            this.errorMessage = null
            this.isError = false
          // } else {
          //   this.isError = true
          //   this.errorMessage = "logo image size" 
          // }
        }else{
          this.isError = true
          this.errorMessage = "image extension is not allowed"
          this.form.image = null
          document.getElementById('image').value = null
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">

              <div class="form-group">
                <label>Description</label>
                <div id="editor-wrapper" :class="{ 'is-invalid': isDescriptionEmtpy }">
                  <ckeditor v-model="form.description" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <div v-if="isDescriptionEmtpy" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Image</label>
                <input :class="{ 'is-invalid': isImageEmtpy }" type="file" id="image" ref="image" accept=".jpg, .jpeg, .png" @change="upload" class="form-control" name="image" />
                <div id="fileUploadHelp" class="form-text">File Type .jpg, .jpeg, .png. </div>
                <div v-if="isImageEmtpy" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>
              <div id="perview">
                <b-img v-if="preview" :src="preview" thumbnail fluid style="max-width: 25%;"></b-img>
              </div>

              <div class="form-group mb-0 mt-3">
                <div>
                  <button type="submit" class="btn btn-primary">Save Change</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>